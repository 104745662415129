<template>
  <v-overlay
    :opacity="1"
    :value="showOverlay"
    class="loading-bg"
  >
    <div style="width: 50vw;">
      <v-row justify="center">
        <v-col cols="auto">
          <span class="text-h4 azure-text">
            Finding a formulation for you...
          </span>
        </v-col>
      </v-row>
      <v-row
        justify="center"
      >
        <v-col
          cols="12"
          v-if="progress != undefined"
        >
          <v-progress-linear
            v-model="progress"
            color="var(--eastman-azure)"
            background-color="#00729A59"
          />
        </v-col>
        <v-col
          cols="auto"
          v-else
        >
          <v-progress-circular
            indeterminate
            width="5"
            size="100"
            color="var(--eastman-azure)"
            background-color="#00729A59"
          />
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="auto">
          <span
            v-if="progress != null"
            class="text-h4 azure-text"
          >
            {{ `${Math.floor(progress)}%` }}
          </span>
        </v-col>
      </v-row>
    </div>
  </v-overlay>
</template>

<script>
export default {
  Name: "LoadingOverlay",
  props: {
    showOverlay: {
      required: true,
      type: Boolean,
    },
    progress: {
      required: false,
      defualt: undefined,
      type: Number,
    },
  },
}
</script>

<style>
.loading-bg .v-overlay__scrim {
  background-color: var(--eastman-blue-washed) !important;
}

</style>